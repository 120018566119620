.group:hover .group-hover\:block {
    display: block;
  }
  
  .group-hover\:block {
    display: none;
  }
  
  .group-hover\:opacity-100 {
    opacity: 1;
  }
  
  .group-hover\:opacity-0 {
    opacity: 0;
  }

  .img-max-height {
    max-height: 600px;
    object-fit: cover; 
  }
  