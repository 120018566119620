.antDiv {
    height: 35px;
    transition: height 0.5s ease;
  }
  
  .antDiv.show {
    height:460px; 
  }

  @media (max-width: 768px) {
    .antDiv {
      height: 47px;
    }
    .antDiv.show {
      height: 600px; 
    }
}