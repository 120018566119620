@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
    font-family: 'Glacial'; 
    src: url('./static/fonts/GlacialIndifference-Regular.otf') format('opentype');
  }
@font-face {
    font-family: 'GlacialBold'; 
    src: url('./static/fonts/GlacialIndifference-Bold.otf') format('opentype');
  }

  .font-lato-300 {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  .font-lato-400 {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .font-lato-500 {
    font-family: "Lato", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  .font-lato-600 {
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  .font-lato-700 {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
  }